/* Make clicks pass-through */
#web-progress {
  pointer-events: none;
}

#web-progress .bar {
  background: #29d;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#web-progress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#web-progress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: calc(50% - 9px);
}

#web-progress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  
  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;

  -webkit-animation: web-progress-spinner 400ms linear infinite;
  animation: web-progress-spinner 400ms linear infinite;
}

.web-progress-custom-parent {
  overflow: hidden;
  position: relative;
}

.web-progress-custom-parent #web-progress .spinner,
.web-progress-custom-parent #web-progress .bar {
  position: absolute;
}

@-webkit-keyframes web-progress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes web-progress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
